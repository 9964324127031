import { ethers } from 'ethers';

const Navigation = ({ account, setAccount }) => {
    const connectHandler = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = ethers.utils.getAddress(accounts[0])
        setAccount(account);
    }

    return (
        <nav>
            <div className='nav__brand'>
                <h1>NFT Generator for Stable Diffusion 2.1 </h1>
                
            </div>
            
            {account ? (
                <button
                    type="button"
                    className='nav__connect'
                >
                    {account.slice(0, 6) + '...' + account.slice(38, 42)}
                </button>
            
            ) : (
                <button
                    type="button"
                    className='nav__connect'
                    onClick={connectHandler}
                >
                    Connect Wallet
                </button>

            )}
            <p className='label'><strong>Smart Contracts Deployed on Sepolia Ethereum Test Net</strong></p>                        
            <p className='label'><strong>Please connect with your MetaMask Wallet</strong></p>        
                       
        </nav>
    );
}

export default Navigation;